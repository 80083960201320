import "./main.css";
import avatar1 from "./assets/avatar1.png";
import avatar2 from "./assets/avatar2.png";

export const Main = () => {
  return (
    <div className="mainContainer">
      <div className="profile">
        <p>octav codrea</p>

        <div className="profilePic">
          <img src={avatar2} alt="profile"></img>
        </div>
      </div>
      <div className="linksContainer">
        <a
          href="https://electronicsun.octavcodrea.com/"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="linkButton highlighted">
            Read <span className="highlight">electronic sun</span>
          </div>
        </a>

        <a
          href="https://signals.octavcodrea.com/"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="linkButton">
            Read <span>signals in the night</span>
          </div>
        </a>

        <a
          href="https://www.teepublic.com/user/codrea"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="linkButton">Shop</div>
        </a>

        <a
          href="https://www.instagram.com/octavcodrea/"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="linkButton">Instagram</div>
        </a>

        <a
          href="https://www.facebook.com/octavcodrea"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="linkButton">Facebook</div>
        </a>
      </div>
    </div>
  );
};
