import React from "react";
import "./main.css";
import { Main } from "./Main";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Octav Codrea - Links</title>
        <meta property="title" content="Octav Codrea - Links" />
        <meta
          property="description"
          content="Find all my relevant links here"
        />
        <meta property="url" content="http://links.octavcodrea.com" />

        <meta property="og:title" content="Octav Codrea - Links" />
        <meta
          property="og:description"
          content="Find all my relevant links here"
        />
        <meta property="og:url" content="http://links.octavcodrea.com" />
        <meta
          property="og:image"
          content="https://d1fdloi71mui9q.cloudfront.net/NuY4PgEwSViXvMsguH5l_yW171b2okfX9tJLC"
        />
        <meta
          property="og:image:secure_url"
          content="https://d1fdloi71mui9q.cloudfront.net/NuY4PgEwSViXvMsguH5l_yW171b2okfX9tJLC"
        />
        <meta property="og:updated_time" content="1608493480000" />
        <meta property="profile:username" content="octav codrea" />
        <meta name="twitter:title" content="Octav Codrea - Links" />
        <meta
          name="twitter:description"
          content="Find all my relevant links here"
        />
        <meta
          name="twitter:image"
          content="https://d1fdloi71mui9q.cloudfront.net/NuY4PgEwSViXvMsguH5l_yW171b2okfX9tJLC"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Main />
    </>
  );
}

export default App;
